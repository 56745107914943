import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import AppContainer from "./containers/AppContainer/AppContainer";
import Login from "./Authentication/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import UsersList from "./Pages/Users/UsersList";
import SubscriptionPlans from "./Pages/SubscriptionPlans/SubscriptionPlans";
import SubscriptionUsers from "./Pages/SubscribtionUsers/SubscriptionUsers";
import Invoices from "./Pages/Invocies/Invoices";
import UsersDetail from "./Pages/Users/UsersDetail";
// import Settings from "./Pages/Accounts/Settings";
import { getFromSessionStorage } from "./utils/sessionStorage";
import ChangePassword from "./Pages/Accounts/ChangePassword";
import AccountSeeting from "./Pages/Accounts/AccountSeeting";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/*" element={<AppContainer />}>
            <Route index element={<Dashboard />} />
            <Route path="Users" element={<UsersList />} />
            <Route path="Users/UserDetail" element={<UsersDetail />} />
            <Route path="SubscriptionPlans" element={<SubscriptionPlans />} />
            <Route path="SubscriptionUsers" element={<SubscriptionUsers />} />
            <Route path="Invoices" element={<Invoices />} />
            {/* <Route path="Account" element={<Settings/>} /> */}
            <Route path="ChangePassword" element={<ChangePassword />} />
            <Route path="AccountSeeting" element={<AccountSeeting />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const PrivateRoutes = () => {
  const authToken = getFromSessionStorage("authToken");
  return authToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
};
export default Router;
