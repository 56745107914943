import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Chip,
  TableCell,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  LinearProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as BlockUserIcon } from "../../assets/images/icons/BlockUserIcon.svg";
import BlockUnBlockMessageDialog from "../../Common/BlockUnBlockMessageDialog";
import { useLocation } from "react-router";
import {
  useGetUsersById,
  useGetSubscriptionHistoryByUsersById,
  usePutBlockUnBlockUser,
} from "../../hooks/common";
import Loader from "../../Common/Loader";

const UsersDetail = () => {
  const [pageHeight, setPageHeight] = useState();
  const [tableHeight, setTableHeight] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const location = useLocation();
  const userIdCurrent = location.state || {};
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setPageHeight(innerHeight - 105);
      setTableHeight(innerHeight - 247);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const [showBlockModal, setShowBlockModal] = useState(false);
  const handleShowBlockModal = () => {
    setShowBlockModal(false);
  };

  const onSuccess = (data) => {
    setShowBlockModal(false);
    setAlertType("success");
    setAlertMessage(
      data?.isblocked
        ? "User Blocked Successfully"
        : "User UnBlocked Successfully"
    );
    setOpenAlert(true);
  };
  const onError = (error) => {
    setShowBlockModal(false);
    setShowBlockModal(false);
    setAlertType("error");
    setAlertMessage(error.message);
    setOpenAlert(true);
  };
  const { data: UserDetails, isLoading } = useGetUsersById(userIdCurrent);
  const { data: SubscriptionDetails, isLoading: isSubscriptionLoading } =
    useGetSubscriptionHistoryByUsersById(userIdCurrent);

  const { mutate: blockUnblockUser, isPending } = usePutBlockUnBlockUser(
    onSuccess,
    onError
  );

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [isUserBlocked, setIsUserBlocked] = useState();
  const handleBlockUnBlock = () => {
    var data = {
      userId: userIdCurrent,
      isBlocked: !UserDetails?.user?.isblocked,
    };
    blockUnblockUser(data);
  };

  function calculateSubscriptionUsage(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();
    const current = now > end ? end : now;

    const totalDuration = end - start;

    const elapsedTime = current - start;

    var usagePercentage = (elapsedTime / totalDuration) * 100;
    if (isNaN(usagePercentage)) {
      usagePercentage = 0;
    }

    return Math.min(Math.round(usagePercentage), 100);
  }

  if (isPending || isSubscriptionLoading || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <Stack
            spacing={3}
            sx={{
              borderRadius: "10px",
              background: "#FFF",
              padding: 2,
              height: { xs: "auto", md: `${pageHeight}px` },
              overflow: "auto",
            }}
          >
            <Stack alignItems={"left"} marginBottom={2}>
              <Typography variant="h5" component={"h5"}>
                User Details
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              bgcolor={"#F5F5F5"}
              padding={2}
              borderRadius={"10px"}
            >
              <Box
                textAlign={"center"}
                flexGrow={1}
                sx={{ borderRight: "1px solid #5E5A70" }}
              >
                <Typography variant="h6" component={"h6"}>
                  {UserDetails?.subscription?.plan_name}
                </Typography>
                <Typography variant="p" component={"p"} color={"#5E5A70"}>
                  Active Sub. Plan
                </Typography>
              </Box>
              <Box textAlign={"center"} flexGrow={1}>
                <Typography variant="h6" component={"h6"}>
                  {UserDetails?.subscription?.durationInDays}
                </Typography>
                <Typography variant="p" component={"p"} color={"#5E5A70"}>
                  Subs. Duration
                </Typography>
              </Box>
            </Stack>

            <Box>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    User Name
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {UserDetails?.user?.firstname +
                      " " +
                      UserDetails?.user?.lastname}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Email
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {UserDetails?.user?.email}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Phone No
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {UserDetails?.user?.phonenumber}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Company
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {UserDetails?.user?.company}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Job Title
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {UserDetails?.user?.jobtitle}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Subscription Start Date
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {formatDate(UserDetails?.subscription?.startDate)}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="p" component={"p"} color={"#5E5A70"}>
                    Subscription End Date
                  </Typography>
                  <Typography variant="p" component={"p"} fontSize={"1rem"}>
                    {formatDate(UserDetails?.subscription?.endDate)}
                  </Typography>
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={1}
                  flexWrap={"wrap"}
                  sx={{ borderTop: "1px dashed black", pt: 2 }}
                >
                  <Typography
                    variant="p"
                    component={"p"}
                    fontSize={"0.875rem"}
                    color={"#5E5A70"}
                  >
                    Subscription Usage
                  </Typography>
                  <Typography
                    variant="p"
                    component={"p"}
                    fontSize={"0.875rem"}
                    color={"#2E80FE"}
                  >
                    {calculateSubscriptionUsage(
                      UserDetails?.subscription?.startDate,
                      UserDetails?.subscription?.endDate
                    ) + "%"}
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={calculateSubscriptionUsage(
                    UserDetails?.subscription?.startDate,
                    UserDetails?.subscription?.endDate
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Box>
            <Box sx={{ borderRadius: "10px", background: "#FFF", padding: 2 }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={2}
                flexWrap={"wrap"}
              >
                <Box>
                  <Typography variant="h5" component="h5">
                    Subscription History
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<BlockUserIcon />}
                    onClick={() => {
                      setShowBlockModal(true);
                      setIsUserBlocked(UserDetails?.user?.isblocked);
                    }}
                  >
                    {UserDetails?.user?.isblocked
                      ? "UnBlock User"
                      : "Block User"}
                  </Button>
                </Box>
              </Stack>
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  overflow: "auto",
                  height: { xs: "auto", md: `${tableHeight}px` },
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ width: "30px", paddingX: 1 }}
                      >
                        #
                      </TableCell>
                      <TableCell align="left">Subscription ID</TableCell>
                      <TableCell align="left">Subs. Plan</TableCell>
                      <TableCell align="left">Start Date</TableCell>
                      <TableCell align="left">End Date</TableCell>
                      <TableCell align="left">Total Days</TableCell>
                      <TableCell align="left">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SubscriptionDetails?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ paddingX: 1 }}
                        >
                          {++index}
                        </TableCell>
                        <TableCell align="left">{row?.id}</TableCell>
                        <TableCell align="left">
                          <Chip
                            size="small"
                            label={row?.plan_name}
                            color={
                              row?.plan_name === "Basic"
                                ? "successChip"
                                : row?.plan_name === "Standard"
                                ? "primaryChip"
                                : "errorChip"
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(row?.startDate)}{" "}
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(row?.endDate)}
                        </TableCell>
                        <TableCell align="left">
                          {row?.durationInDays}
                        </TableCell>

                        <TableCell align="left" sx={{ color: "primary.main" }}>
                          {row?.paymentCurrency == "USD" ||
                          row?.paymentCurrency == "usd"
                            ? "$" + row?.subscriptionPrice
                            : row?.subscriptionPrice +
                              " " +
                              row?.paymentCurrency}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertType} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <BlockUnBlockMessageDialog
        showBlockModal={showBlockModal}
        isBlocked={isUserBlocked}
        handleShowBlockModal={handleShowBlockModal}
        handleBlockUnBlock={handleBlockUnBlock}
      />
    </>
  );
};

export default UsersDetail;
