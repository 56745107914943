import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TablePagination,
  TextField,
  InputAdornment,
  Typography,
  Menu,
  Grid,
  InputLabel,
} from "@mui/material";
import { ReactComponent as FilterIcon } from "../../assets/images/icons/filter-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import { useLocation } from "react-router";
import { useGetSubscribedUsersByPlaneId } from "../../hooks/common";

const SubscriptionUsers = () => {
  const [tableHeight, setTableHeight] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [searchString, setSearchString] = useState("");

  const location = useLocation();
  const rowData = location.state || {};
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTableHeight(innerHeight - 239);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const { data: SubscribedUsers } = useGetSubscribedUsersByPlaneId(
    rowData,
    page + 1,
    rowsPerPage,
    startDate,
    endDate,
    searchString
  );

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFilterApply = () => {
    setStartDate(`${startDateFilter}T00:01`);
    setEndDate(`${endDateFilter}T:23:59`);
    handleFilterClose();
  };
  const handleFilterClear = () => {
    setStartDate("");
    setEndDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    handleFilterClose();
  };

  return (
    <>
      <Box sx={{ borderRadius: "10px", background: "#FFF" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
          sx={{ padding: 2, paddingBottom: 0 }}
        >
          <Typography variant="h5" component="h5">
            Subscribed Users
          </Typography>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <Box>
              <TextField
                placeholder="Search"
                variant="outlined"
                color="primary"
                size="small"
                hiddenLabel
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="grey"
                startIcon={<FilterIcon />}
                onClick={handleFilterClick}
              >
                Filter
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleFilterClose}
                sx={{
                  mt: 1,
                  "& .MuiPaper-root": {
                    width: "487px",
                    padding: "2px",
                    borderRadius: "8px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.01), 0px 3px 14px 2px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <Box sx={{ px: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      borderBottom: "1px solid #D4D4D4",
                      paddingBottom: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    Apply Filter
                  </Typography>
                  <Grid container spacing={2} pt={1}>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          color: "#666672",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        From Date
                      </InputLabel>
                      <TextField
                        placeholder="Please enter"
                        type="date"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        hiddenLabel
                        size="small"
                        value={startDateFilter}
                        onChange={(e) => setStartDateFilter(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          color: "#666672",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        To Date
                      </InputLabel>
                      <TextField
                        placeholder="Please enter"
                        type="date"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        hiddenLabel
                        size="small"
                        value={endDateFilter}
                        onChange={(e) => setEndDateFilter(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  p={2}
                  pb={0}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        color: "#666672",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Availability
                    </InputLabel>
                  </Box>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    justifyContent={"flex-end"}
                  >
                    <InputLabel
                      sx={{
                        color: "#666672",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Is Blocked
                    </InputLabel>
                    <CustomSwitch />
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    pt: 2,
                    px: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="grey"
                    onClick={handleFilterClear}
                  >
                    Cancel
                  </Button>
                  <Box sx={{ mx: 1 }} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFilterApply}
                  >
                    Apply
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              overflow: "auto",
              height: { xs: "auto", md: `${tableHeight}px` },
              boxShadow: "none",
              marginTop: "10px",
              position: "relative",
            }}
          >
            <Table stickyHeader sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ width: "30px", paddingX: 1 }}>
                    #
                  </TableCell>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left">Remaining Days</TableCell>
                  <TableCell align="left">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SubscribedUsers?.usersData?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { backgroundColor: "#E6F3FB66" },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{ paddingX: 1 }}>
                      {++index}
                    </TableCell>
                    <TableCell align="left">
                      {row?.firstname + " " + row?.lastname}
                    </TableCell>

                    <TableCell align="left">
                      {formatDate(row?.subscriptionStartDate)}
                    </TableCell>
                    <TableCell align="left">
                      {formatDate(row?.subscriptionEndDate)}
                    </TableCell>
                    <TableCell align="left">
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(row?.subscriptionEndDate) - new Date()) /
                            (1000 * 60 * 60 * 24)
                        )
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "primary.main" }}>
                      ${row?.subscriptionPrice}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Box sx={{ paddingX: 3, borderTop: "1px solid #D4D4D4" }}>
          <TablePagination
            component="div"
            count={SubscribedUsers?.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionUsers;
