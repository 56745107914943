import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginData } from "../../Authentication/Login/loginSlice";
import { setUser } from "../../store/userSlice";
import { setToSessionStorage } from "../../utils/sessionStorage";
import {
  login,
  getAllUsers,
  getAllSubscriptionPlans,
  getAccountData,
  putPasswordChange,
  putAdminUpdate,
  getUsersById,
  getSubscriptionHistoryByUsersById,
  getSubscribedUsersByPlaneId,
  putBlockUnBlockUser,
  getDashbardContent,
  getSearchUsers,
  getDashbardsubscriptionAmount,
  getAllUsersDownload,
} from "../../services";

export const useLogin = (onError, onSuccess) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ email, password }) => {
      const response = await login(email, password);
      return response.data;
    },
    onSuccess: (data) => {
      setToSessionStorage("authToken", data?.token);
      dispatch(setLoginData({ ...data }));
      dispatch(setUser({ ...data }));
      navigate("/");
    },
    onError,
  });
};

export const useGetAllUsers = (page, rowsPerPage, startDate, endDate) => {
  return useQuery({
    queryKey: ["getAllUsers", { page, rowsPerPage, startDate, endDate }],
    queryFn: () => getAllUsers(page, rowsPerPage, startDate, endDate),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetAllUsersDownload = () => {
  return useQuery({
    queryKey: ["getAllUsersDownload"],
    queryFn: () => getAllUsersDownload(),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetSearchUsers = (searchString, rowsPerPage, page, enabled) => {
  return useQuery({
    queryKey: ["getSearchUsers", { searchString, rowsPerPage, page }],
    queryFn: () => getSearchUsers(searchString, rowsPerPage, page),
    enabled: enabled,
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetDashbardContent = () => {
  return useQuery({
    queryKey: ["getDashbardContent"],
    queryFn: () => getDashbardContent(),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetDashbardsubscriptionAmount = (year) => {
  return useQuery({
    queryKey: ["getDashbardsubscriptionAmount", year],
    queryFn: () => getDashbardsubscriptionAmount(year),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetUsersById = (userId) => {
  return useQuery({
    queryKey: ["UsersById", { userId }],
    queryFn: () => getUsersById(userId),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetSubscriptionHistoryByUsersById = (userId) => {
  return useQuery({
    queryKey: ["getSubscriptionHistoryByUsersById", { userId }],
    queryFn: () => getSubscriptionHistoryByUsersById(userId),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetSubscribedUsersByPlaneId = (
  PlaneId,
  page,
  rowsPerPage,
  startDate,
  endDate,
  searchString
) => {
  return useQuery({
    queryKey: [
      "getSubscribedUsersByPlaneId",
      { PlaneId, page, rowsPerPage, startDate, endDate, searchString },
    ],
    queryFn: () =>
      getSubscribedUsersByPlaneId(
        PlaneId,
        page,
        rowsPerPage,
        startDate,
        endDate,
        searchString
      ),

    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetAllSubscriptionPlans = () => {
  return useQuery({
    queryKey: ["getAllSubscriptionPlans"],
    queryFn: () => getAllSubscriptionPlans(),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useGetAccountData = () => {
  return useQuery({
    queryKey: ["getAccountData"],
    queryFn: () => getAccountData(),
    select: (data) => {
      return data?.data;
    },
    onError: () => {},
  });
};

export const useAdminUpdate = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await putAdminUpdate(data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAccountData");
      onSuccess(data);
    },
    onError,
  });
};
export const usePasswordChange = (onSuccess, onError) => {
  return useMutation({
    mutationFn: async (passwords) => {
      const response = await putPasswordChange(passwords);
      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const usePutBlockUnBlockUser = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const response = await putBlockUnBlockUser(data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("getAllUsers");
      queryClient.invalidateQueries("UsersById");
      onSuccess(data);
    },
    onError,
  });
};
