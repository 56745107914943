import { fetchWrapper } from "./restApi";
const API_URL_GET = process.env.REACT_APP_BASE_URL_DEV;

export const login = (email, password) => {
  return fetchWrapper("POST", `${API_URL_GET}/signin`, {
    email: email,
    password: password,
  });
};

export const getAllUsers = (page, rowsPerPage, startDate, endDate) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/users?${rowsPerPage && `limit=${rowsPerPage}&`}${
      page && `page=${page}&`
    }startDate=${startDate}&endDate=${endDate}`
  );
};

export const getAllUsersDownload = () => {
  return fetchWrapper("GET", `${API_URL_GET}/users/export`);
};

export const getSearchUsers = (searchString, rowsPerPage, page) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/users/search?searchString=${searchString}&${
      rowsPerPage && `limit=${rowsPerPage}&`
    }${page && `page=${page}`}`
  );
};

export const getDashbardContent = () => {
  return fetchWrapper("GET", `${API_URL_GET}/dashboard`);
};

export const getDashbardsubscriptionAmount = (year) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/dashboard/subscription-amount?year=${year}`
  );
};

export const getUsersById = (userId) => {
  return fetchWrapper("GET", `${API_URL_GET}/users/details?userId=${userId}`);
};

export const getSubscriptionHistoryByUsersById = (userId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/users/subscription-history?userId=${userId}`
  );
};

export const getSubscribedUsersByPlaneId = (
  PlaneId,
  page,
  rowsPerPage,
  startDate,
  endDate,
  searchString
) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/users/subscribed/${PlaneId}?${
      rowsPerPage && `limit=${rowsPerPage}&`
    }${
      page && `page=${page}&`
    }startDate=${startDate}&endDate=${endDate}&searchString=${searchString}`
  );
};

export const getAllSubscriptionPlans = () => {
  return fetchWrapper("GET", `${API_URL_GET}/subscription/plans`);
};

export const getAccountData = () => {
  return fetchWrapper("GET", `${API_URL_GET}/current`);
};

export const putAdminUpdate = (data) => {
  return fetchWrapper("PUT", `${API_URL_GET}/`, data);
};

export const putPasswordChange = (passwords) => {
  return fetchWrapper("PUT", `${API_URL_GET}/change-password`, passwords);
};

export const putBlockUnBlockUser = (data) => {
  return fetchWrapper("PUT", `${API_URL_GET}/user-block`, data);
};
