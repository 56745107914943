import { Stack, CircularProgress } from "@mui/material";
import React from "react";
const Loader = () => {
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          position: "absolute",
          zIndex: 1310,
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CircularProgress />
      </Stack>
    </>
  );
};
export default Loader;
