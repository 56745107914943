import React from "react";
import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DeleteModelIcon from "../assets/images/icons/delete-modal-icon.svg";

const BlockUnBlockMessageDialog = ({
  showBlockModal,
  isBlocked,
  handleShowBlockModal,
  handleBlockUnBlock,
}) => {
  return (
    <React.Fragment>
      <Dialog open={showBlockModal} onClose={handleShowBlockModal}>
        <DialogContent
          sx={{ padding: 3, borderTop: "0px !important", paddingX: 9 }}
        >
          <Stack gap={1} alignItems={"center"}>
            <Box>
              <img src={DeleteModelIcon} alt="" />
            </Box>
            <Typography variant="p" paddingTop={1}>
              {isBlocked
                ? "Are you sure to Un Block user?"
                : "Are you sure to block user?"}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
          <Box flexGrow={1}>
            <Button
              color="grey"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleShowBlockModal}
            >
              Cancel
            </Button>
          </Box>
          <Box flexGrow={1}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="error"
              onClick={handleBlockUnBlock}
            >
              {isBlocked ? "Yes, Un Block" : "Yes, Block"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default BlockUnBlockMessageDialog;
