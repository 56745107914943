import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Router from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
