import { Outlet } from "react-router-dom";
import Navbar from "../../Common/Navbar";
import { Container } from "@mui/material";

const AppContainer = () => {
  return (
    <>
      <Navbar />
      <Container
        maxWidth={false}
        sx={{
          paddingRight: { xs: "15px" },
          paddingLeft: { xs: "15px" },
          paddingTop: "87px",
          paddingBottom: "17px",
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default AppContainer;
