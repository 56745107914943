import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Stack,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";
import { useAdminUpdate, useGetAccountData } from "../../hooks/common";
import Loader from "../../Common/Loader";

const AccountSeeting = () => {
  const [fullName, setFullName] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [companyName, setCompanyName] = useState();
  const [fullNameError, setFullNameError] = useState();
  const [emailAddressError, setEmailAddressError] = useState();
  const [companyNameError, setCompanyNameError] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const onSuccessAdmin = () => {
    setAlertType("success");
    setAlertMessage("Records Updated Successfully");
    setOpenAlert(true);
  };
  const onErrorAdmin = (error) => {
    setAlertType("error");
    setAlertMessage(error.message);
    setOpenAlert(true);
  };
  const { data: AccountData, isLoading } = useGetAccountData();

  const { mutate: UpdateAdminData, isPending: isAdminUpdating } =
    useAdminUpdate(onSuccessAdmin, onErrorAdmin);

  useEffect(() => {
    setFullName(AccountData?.user?.name);
    setEmailAddress(AccountData?.user?.email);
    setCompanyName(AccountData?.user?.companyname);
  }, [AccountData]);

  const handleUpadteAdminData = (e) => {
    e.preventDefault();
    if (fullName === "" || fullName === null) {
      setFullNameError("Please Enter Full Name");
      return;
    }
    if (emailAddress === "" || emailAddress === null) {
      setEmailAddressError("Please Enter Email");
      return;
    }

    if (companyName === "" || companyName === null) {
      setCompanyNameError("Please Enter Company Name");
      return;
    }

    var data = {
      email: emailAddress,
      name: fullName,
      companyname: companyName,
    };
    UpdateAdminData(data);
  };

  const handleCancelAdminData = () => {
    setFullName(AccountData?.user?.name);
    setEmailAddress(AccountData?.user?.email);
    setCompanyName(AccountData?.user?.companyname);
    handleFocusFullName();
    setEmailAddressError();
    handleFocusCompanyName();
  };

  const handleFocusFullName = () => {
    setFullNameError("");
  };
  const handleFocusEmailAddress = () => {
    setEmailAddressError("");
  };

  const handleFocusCompanyName = () => {
    setCompanyNameError("");
  };

  if (isLoading || isAdminUpdating) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleUpadteAdminData}>
      <Box
        sx={{
          position: { xs: "block", sm: "absolute" },
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          sx={{ paddingTop: { xs: "0", sm: "70px" } }}
        >
          <Box
            bgcolor={"#ffffff"}
            sx={{
              height: { xs: "100%", sm: "auto" },
              maxWidth: { xs: "100%", sm: "450px" },
              borderRadius: "25px",
            }}
          >
            <Typography
              variant="h5"
              component={"h5"}
              borderBottom={"1px solid #666672"}
              padding={3}
            >
              Account Seeting
            </Typography>

            <Grid container spacing={3} padding={3}>
              <Grid item xs={12}>
                <InputLabel>Full Name</InputLabel>
                <TextField
                  placeholder="Please enter"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  onFocus={handleFocusFullName}
                  error={!!fullNameError}
                  helperText={fullNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel> Email Address</InputLabel>
                <TextField
                  placeholder="Please enter"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  onFocus={handleFocusEmailAddress}
                  error={!!emailAddressError}
                  helperText={emailAddressError}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Company Name</InputLabel>
                <TextField
                  placeholder="Please enter"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  onFocus={handleFocusCompanyName}
                  error={!!companyNameError}
                  helperText={companyNameError}
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              gap={2}
              flexWrap={"wrap"}
              padding={3}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCancelAdminData}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Snackbar
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={alertType} sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </form>
  );
};

export default AccountSeeting;
