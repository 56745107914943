import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import "./Login.scss";
import logo from "../../assets/images/logos/ve-toolkit-logo.svg";
import { useLogin } from "../../hooks/common";
import { BadRequestError } from "../../utils/errorTypes";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const onError = (error) => {
    if (error instanceof BadRequestError) {
      setEmailError("Invalid email");
      setPasswordError("Invalid password");
    } else {
      setEmailError("Error occurred during login");
      setPasswordError("Error occurred during login");
    }
  };

  const { mutate: login, isLoading } = useLogin(onError);
  const handleLogin = (e) => {
    e.preventDefault();
    if(!email){
      setEmailError("Please Enter Email");
    } else  if(!password){
      setPasswordError("Please Enter Password");
    } else{
      login({ email, password });
    }
  };
  const handleFocus = () => {
    setPasswordError("");
    setEmailError("");
  };

  return (
    <form className="login-bg" onSubmit={handleLogin}>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <Box
          bgcolor={"#ffffff"}
          sx={{
            padding: { xs: 3, sm: 5 },
            height: { xs: "100%", sm: "auto" },
            width: { xs: "100%", sm: "auto" },
            borderRadius: { xs: "0px", sm: "25px" },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              sx={{ paddingTop: { xs: 5, sm: 0 } }}
            >
              <img src={logo} alt="logo" />
            </Stack>
            <Typography
              variant="h5"
              component={"h5"}
              mt={6}
              color={"primary.main"}
              sx={{ fontSize: "28px" }}
            >
              Admin Sign in
            </Typography>
            <Typography variant="body2" color={"#666672"}>
              Please enter your Credentials to sign in your account.
            </Typography>
          </Box>
          <Box mt={6}>
            <InputLabel>Email</InputLabel>
            <TextField
              name="email"
              size="small"
              placeholder="Enter your email address"
              variant="outlined"
              color="primary"
              type="email"
              fullWidth
              onFocus={handleFocus}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={!!emailError}
              helperText={emailError}
            />
            <Box mt={3}>
              <InputLabel>Password</InputLabel>
              <TextField
                name="password"
                type="password"
                size="small"
                placeholder="Enter your password"
                variant="outlined"
                color="primary"
                fullWidth
                onFocus={handleFocus}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                error={!!passwordError}
                helperText={passwordError}
              />
            </Box>
            <Stack direction={"row"} justifyContent={"center"} mt={6}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{ px: 9 }}
                disabled={isLoading}
              >
                {isLoading ? "Signing In..." : "Sign In"}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </form>
  );
};

export default Login;
