import { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TotalUsersIcon from "../../assets/images/icons/TotalUsersIcon.png";
import ActiveUsersIcon from "../../assets/images/icons/ActiveUsersIcon.png";
import UserProfile from "../../assets/images/imgs/UserProfile.png";
import {
  useGetAllUsers,
  useGetDashbardContent,
  useGetDashbardsubscriptionAmount,
} from "../../hooks/common";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
const Dashboard = () => {
  const currentYear = new Date().getFullYear();
  const initialYears = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const [years, setYears] = useState(initialYears);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedDay, setSelectedDay] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const { data: DashboardContent } = useGetDashbardContent();

  const { data: DashbardsubscriptionAmount } =
    useGetDashbardsubscriptionAmount(selectedYear);

  const { data: AllUsers, isLoading } = useGetAllUsers(
    "",
    "",
    `${startDate}T00:01`,
    `${endDate}T23:59`
  );

  const loadMoreYears = () => {
    const lastYear = years[years.length - 1];
    const moreYears = Array.from({ length: 5 }, (_, i) => lastYear - (i + 1));
    setYears((prevYears) => [...prevYears, ...moreYears]);
  };

  // Function to get the start and end dates for the current week
  const getThisWeekDates = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const endOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    );
    const formatDate = (date) => date.toISOString().split("T")[0];
    return {
      start: formatDate(startOfWeek),
      end: formatDate(endOfWeek),
    };
  };

  // Function to get the start and end dates for the current month
  const getThisMonthDates = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const formatDate = (date) => date.toISOString().split("T")[0];
    return {
      start: formatDate(startOfMonth),
      end: formatDate(endOfMonth),
    };
  };

  // Function to get the start and end dates for the current year
  const getThisYearDates = () => {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const endOfYear = new Date(today.getFullYear(), 11, 31);
    const formatDate = (date) => date.toISOString().split("T")[0];
    return {
      start: formatDate(startOfYear),
      end: formatDate(endOfYear),
    };
  };

  const handleSelectDay = (e) => {
    var SelectedValue = e.target.value;
    setSelectedDay(e.target.value);

    if (SelectedValue == 1) {
      setStartDate(new Date().toISOString().split("T")[0]);
      setEndDate(new Date().toISOString().split("T")[0]);
    } else if (SelectedValue == 2) {
      setStartDate(getThisWeekDates().start);
      setEndDate(getThisWeekDates().end);
    } else if (SelectedValue == 3) {
      setStartDate(getThisMonthDates().start);
      setEndDate(getThisMonthDates().end);
    } else if (SelectedValue == 4) {
      setStartDate(getThisYearDates().start);
      setEndDate(getThisYearDates().end);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            direction={"row"}
            className="TotalUsersBg"
            spacing={2}
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: "15px",
            }}
          >
            <Box>
              <img src={TotalUsersIcon} alt="" />
            </Box>
            <Box>
              <Typography
                component={"p"}
                variant="p"
                color={"#5E5A70"}
                fontSize={"0.875rem"}
              >
                Total Users
              </Typography>
              <Typography
                component={"h4"}
                variant="h4"
                color={"#5E5A70"}
                lineHeight={"1"}
              >
                {DashboardContent?.total_users}
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            direction={"row"}
            className="ActiveUsersBg"
            spacing={2}
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: "15px",
            }}
          >
            <Box>
              <img src={ActiveUsersIcon} alt="" />
            </Box>
            <Box>
              <Typography
                component={"p"}
                variant="p"
                color={"#5E5A70"}
                fontSize={"0.875rem"}
              >
                Active Users
              </Typography>
              <Typography
                component={"h4"}
                variant="h4"
                color={"#5E5A70"}
                lineHeight={"1"}
              >
                {DashboardContent?.total_active_users}
              </Typography>
            </Box>
          </Stack>
        </Grid> 
      </Grid>

      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: "15px",
              overflowX: "auto",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography variant="h5" component={"h5"}>
                Revenue Analytics
                </Typography>
              </Box>
              <Box>
                <Select
                  fullWidth
                  hiddenLabel
                  variant="filled"
                  size="small"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  displayEmpty
                  renderValue={(selected) =>
                    selected?.length === 0 ? "This Year" : selected
                  }
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                  <MenuItem value={selectedYear} onClick={loadMoreYears}>
                    Load More
                  </MenuItem>
                </Select>
              </Box>
            </Stack>
            <div id="chart3">
              <ReactApexChart
                options={{
                  chart: {
                    type: "bar",
                    height: 350,
                    toolbar: {
                      show: false, // Remove the toolbar (including download button)
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "30%",
                      endingShape: "rounded", // Rounded corners at the top of bars
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  xaxis: {
                    categories: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    labels: {
                      style: {
                        fontSize: "14px", // Increase x-axis font size
                        colors: "#666672",
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      formatter: function (val) {
                        return "$" + val; // Add $ sign before the value
                      },
                      style: {
                        fontSize: "14px", // Increase y-axis font size
                        colors: "#666672",
                      },
                    },
                  },
                  fill: {
                    opacity: 1,
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return "$ " + val;
                      },
                    },
                  },
                  legend: {
                    show: false, // Hide the legend
                  },
                }}
                series={[
                  {
                    name: "Revenue Amount",
                    data: DashbardsubscriptionAmount,
                    color: "#0089D7",
                  },
                ]}
                type="bar"
                height={350}
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: "15px",
              overflowX: "auto",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography variant="h5" component={"h5"}>
                  Users Registered
                </Typography>
              </Box>
              <Box>
                <Select
                  hiddenLabel
                  fullWidth
                  variant="filled"
                  size="small"
                  displayEmpty
                  value={selectedDay}
                  onChange={handleSelectDay}
                >
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>This Weak</MenuItem>
                  <MenuItem value={3}>This Month</MenuItem>
                  <MenuItem value={4}>This Year</MenuItem>
                </Select>
              </Box>
            </Stack>
            <Box sx={{ position: "relative", minHeight: "355px" }}>
              {isLoading ? (
                <Stack
                  direction={"row"}
                  sx={{
                    padding: 2,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: "40px",
                    right: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Stack>
              ) : AllUsers?.users && AllUsers.users.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    overflow: "auto",
                    boxShadow: "none",
                    marginTop: "10px",
                  }}
                >
                  <Table sx={{ minWidth: 420 }} size="small">
                    <TableBody>
                      {AllUsers.users.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "& td, & th": { border: 0, paddingX: 1 },
                          }}
                        >
                          <TableCell align="left">
                            <Stack direction={"row"} spacing={"10px"}>
                              <Box
                                sx={{
                                  width: "3px",
                                  backgroundColor: "#0089D7",
                                  borderRadius: "5px",
                                  my: "4px !important",
                                }}
                              ></Box>
                              <Box>
                                <img
                                  src={UserProfile}
                                  height={35}
                                  width={35}
                                  alt=""
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="p"
                                  component={"p"}
                                  lineHeight={1}
                                >
                                  {row.firstname + " " + row.lastname}
                                </Typography>
                                <Typography
                                  variant="p"
                                  component={"p"}
                                  color={"#5E5A70"}
                                >
                                  {row.email}
                                </Typography>
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="p" component={"p"}>
                              {row.company}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="p" component={"p"}>
                              {row.jobtitle}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      {AllUsers?.totalCount > 20 && (
                        <TableRow
                          sx={{
                            "& td, & th": { border: 0, paddingX: 1 },
                          }}
                        >
                          <TableCell align="center" colSpan={3}>
                            <Link
                              to="/Users"
                              className="text-primary underline"
                            >
                              {" "}
                              View more{" "}
                            </Link>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Stack
                  direction={"row"}
                  sx={{
                    padding: 2,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: "40px",
                    right: 0,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component={"h5"}>
                    No users found.
                  </Typography>
                </Stack>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
