import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Stack,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";
import { usePasswordChange } from "../../hooks/common";
import Loader from "../../Common/Loader";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setcurrentPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordStrongError, setPasswordStrongError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const onError = (error) => {
    if (error.message === "Incorrect current password") {
      setcurrentPasswordError(error.message);
    }
    setAlertType("error");
    setAlertMessage(error.message);
    setOpenAlert(true);
  };
  const onSuccess = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setAlertType("success");
    setAlertMessage("Password Change Successfully");
    setOpenAlert(true);
  };

  const { mutate: ChangePassword, isPending: isPasswordChanging } =
    usePasswordChange(onSuccess, onError);

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (currentPassword === "" || currentPassword === null) {
      setcurrentPasswordError("Please Enter Current Password");
      return;
    }

    if (!strongPasswordRegex.test(newPassword)) {
      setPasswordStrongError(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character."
      );
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordMatchError("New password and confirm password do not match.");
      return;
    }

    var passwords = {
      password: newPassword,
      confirmpassword: confirmNewPassword,
      currentPassword: currentPassword,
    };
    ChangePassword(passwords);
  };

  const handleCancelPassword = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    handleFocusNewPassword();
    handleFocusCurrentPassword();
  };
  const handleFocusNewPassword = () => {
    setPasswordMatchError("");
    setPasswordStrongError("");
  };
  const handleFocusCurrentPassword = () => {
    setcurrentPasswordError("");
  };

  if (isPasswordChanging) {
    return <Loader />;
  }

  return (
    <form onSubmit={handlePasswordChange}>
      <Box
        sx={{
          position: { xs: "block", sm: "absolute" },
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          sx={{ paddingTop: { xs: "0", sm: "70px" } }}
        >
          <Box
            bgcolor={"#ffffff"}
            sx={{
              height: { xs: "100%", sm: "auto" },
              maxWidth: { xs: "100%", sm: "450px" },
              borderRadius: "25px",
            }}
          >
            <Typography
              variant="h5"
              component={"h5"}
              borderBottom={"1px solid #666672"}
              padding={3}
            >
              Change Password
            </Typography>

            <Grid container spacing={3} padding={3}>
              <Grid item xs={12}>
                <InputLabel>Current Password</InputLabel>
                <TextField
                  placeholder="Please enter"
                  type="password"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  onFocus={handleFocusCurrentPassword}
                  error={!!currentPasswordError}
                  helperText={currentPasswordError}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>New Password</InputLabel>
                <TextField
                  placeholder="Please enter"
                  type="password"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  onFocus={handleFocusNewPassword}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!passwordStrongError}
                  helperText={passwordStrongError}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Confirm New Password</InputLabel>
                <TextField
                  placeholder="Please enter"
                  type="password"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  hiddenLabel
                  size="small"
                  onFocus={handleFocusNewPassword}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  error={!!passwordMatchError}
                  helperText={passwordMatchError}
                />
              </Grid>
            </Grid>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              gap={2}
              flexWrap={"wrap"}
              padding={3}
            >
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCancelPassword}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Snackbar
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity={alertType} sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </form>
  );
};

export default ChangePassword;
