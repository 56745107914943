import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Stack,
  SvgIcon,
  TablePagination,
  TextField,
  InputAdornment,
  Typography,
  Menu,
  Grid,
  InputLabel,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/icons/filter-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/DownloadIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  useGetAllUsers,
  useGetAllUsersDownload,
  useGetSearchUsers,
  usePutBlockUnBlockUser,
} from "../../hooks/common";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import Loader from "../../Common/Loader";
import BlockUnBlockMessageDialog from "../../Common/BlockUnBlockMessageDialog";
import { jsonToCsv } from "../../utils/jsonToCsv";
const UsersList = () => {
  const [tableHeight, setTableHeight] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchString, setSearchString] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [enabledFilter, setEnabledFilter] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTableHeight(innerHeight - 239);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const {
    data: AllUsers,
    refetch: refetchAllUsers,
    isLoading,
  } = useGetAllUsers(page + 1, rowsPerPage, startDate, endDate);

  const {
    data: SearchedUsers,
    refetch: getSearchedUserFilter,
    isLoading: isSearching,
  } = useGetSearchUsers(searchString, rowsPerPage, page + 1, enabledFilter);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
    if (
      e.target.value == "" ||
      e.target.value == null ||
      e.target.value == undefined
    ) {
      refetchAllUsers();
      setEnabledFilter(false);
    } else {
      setEnabledFilter(true);
      getSearchedUserFilter();
    }
  };
  const onSuccess = () => {};

  const { mutate: blockUnblockUser, isPending } =
    usePutBlockUnBlockUser(onSuccess);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleUserDetailsView = (row) => {
    navigate("/Users/UserDetail", { state: row.id });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFilterApply = () => {
    setStartDate(`${startDateFilter}T00:01`);
    setEndDate(`${endDateFilter}T:23:59`);
    handleFilterClose();
  };
  const handleFilterClear = () => {
    setStartDate("");
    setEndDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    handleFilterClose();
  };

  const [showBlockModal, setShowBlockModal] = useState(false);
  const [userId, setUserId] = useState(false);

  const handleShowBlockModal = () => {
    setShowBlockModal(false);
  };
  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const handleBlockUnBlock = () => {
    var data = {
      userId: userId,
      isBlocked: isUserBlocked,
    };
    blockUnblockUser(data);
    handleShowBlockModal();
  };

  var usersToDisplay = searchString ? SearchedUsers?.users : AllUsers?.users;
  var totalCount = searchString
    ? SearchedUsers?.totalCount
    : AllUsers?.totalCount;

  var tableIndex = page * rowsPerPage + 1;

  const { data: AllUsersDownload } = useGetAllUsersDownload();
  const handleExportCsv = () => {
    const csv = jsonToCsv(AllUsersDownload);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "VE-Toolkit UsersList.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box sx={{ borderRadius: "10px", background: "#FFF" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
          sx={{ padding: 2, paddingBottom: 0 }}
        >
          <Typography variant="h5" component="h5">
            User Listing
          </Typography>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <TextField
              placeholder="Search"
              variant="outlined"
              color="primary"
              size="small"
              hiddenLabel
              value={searchString}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="grey"
              startIcon={<FilterIcon />}
              onClick={handleFilterClick}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportCsv}
              startIcon={<DownloadIcon />}
            >
              Export CSV
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleFilterClose}
              sx={{
                mt: 1, 
                "& .MuiPaper-root": {
                  width: "487px", 
                  padding: "2px", 
                  borderRadius: "8px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.05),0px 8px 10px 1px rgba(0,0,0,0.01),0px 3px 14px 2px rgba(0,0,0,0.1)",
                },
              }}
            >
              <Box sx={{ px: 2 }}>
                <Typography
                  variant="h6"
                  sx={{
                    borderBottom: "1px solid #D4D4D4",
                    paddingBottom: "8px",
                    marginBottom: "8px",  
                  }}
                >
                  Apply Filter
                </Typography>
                <Grid container spacing={2} pt={1}>
                  <Grid item xs={6}>
                    <InputLabel
                      sx={{
                        color: "#666672",
                        fontWeight: 500,
                        fontSize: "0.875rem",
                      }}
                    >
                      From Date
                    </InputLabel>
                    <TextField
                      placeholder="Please enter"
                      type="date"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      hiddenLabel
                      size="small"
                      value={startDateFilter}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel
                      sx={{
                        color: "#666672",
                        fontWeight: 500,
                        fontSize: "0.875rem",
                      }}
                    >
                      To Date
                    </InputLabel>
                    <TextField
                      placeholder="Please enter"
                      type="date"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      hiddenLabel
                      size="small"
                      value={endDateFilter}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  pt: 2,
                  px: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="grey"
                  onClick={handleFilterClear}
                >
                  Cancel
                </Button>
                <Box sx={{ mx: 1 }} /> 
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilterApply}
                >
                  Apply
                </Button>
              </Box>
            </Menu>
          </Stack>

          <TableContainer
            component={Paper}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              overflow: "auto",
              height: { xs: "auto", md: `${tableHeight}px` },
              boxShadow: "none",
              marginTop: "10px",
              position: "relative",
            }}
          >
            {isLoading || isPending ? (
              <Loader />
            ) : (
              <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: "30px", paddingX: 1 }}>
                      #
                    </TableCell>
                    <TableCell align="left">Full Name</TableCell>
                    <TableCell align="left">Date of Joining</TableCell>
                    <TableCell align="left">Company Name</TableCell>
                    <TableCell align="left">Job Title</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone No#</TableCell>
                    <TableCell align="right">Availability</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersToDisplay?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { backgroundColor: "#E6F3FB66" },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ paddingX: 1 }}
                      >
                        {tableIndex++}
                      </TableCell>
                      <TableCell align="left">
                        {row?.firstname + " " + row?.lastname}
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(row?.createdat)}
                      </TableCell>
                      <TableCell align="left">{row?.company}</TableCell>
                      <TableCell align="left">{row?.jobtitle}</TableCell>
                      <TableCell align="left">{row?.email}</TableCell>
                      <TableCell align="left">{row?.phonenumber}</TableCell>
                      <TableCell align="right">
                        {row?.isblocked ? "Blocked" : "UnBlocked"}
                        <CustomSwitch
                          checked={row?.isblocked}
                          onChange={(e) => {
                            setShowBlockModal(true);
                            setIsUserBlocked(e.target.checked);
                            setUserId(row.id);
                          }}
                          sx={{ marginLeft: 1 }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{ justifyContent: "end" }}
                        >
                          <IconButton
                            size="small"
                            className="EyeIconButton"
                            onClick={() => handleUserDetailsView(row)}
                          >
                            <SvgIcon component={EyeIcon} inheritViewBox />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Stack>
        <Box sx={{ paddingX: 3, borderTop: "1px solid #D4D4D4" }}>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      <BlockUnBlockMessageDialog
        showBlockModal={showBlockModal}
        isBlocked={!isUserBlocked}
        handleShowBlockModal={handleShowBlockModal}
        handleBlockUnBlock={handleBlockUnBlock}
      />
    </>
  );
};

export default UsersList;
