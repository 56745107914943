import * as React from "react";
import {
  AppBar,
  Container,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Button,
  Tooltip,
  MenuItem,
  Stack,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoLight from "../assets/images/logos/ve-toolkit-logo-light.svg";
import { ReactComponent as LeftArrowIcon } from "../assets/images/icons/LeftArrowIcon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../Authentication/Login/loginSlice";
import { userLoggedOut } from "../store/userSlice";
import { useDispatch } from "react-redux";
import UserAvatar from "../assets/images/imgs/ImageUploadeIcon.png";
import { useGetAccountData } from "../hooks/common";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: AccountData } = useGetAccountData();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(userLoggedOut());
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <AppBar position="fixed" sx={{ boxShadow: "0px 2px 115px 0px #00000014" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <Typography
              variant="h6"
              sx={{
                pr: 2,
                borderRight: "0.5px solid #B0DAF3",
                display: { xs: "none", md: "flex" },
              }}
            >
              <img src={LogoLight} alt="VEToolkit" />
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}></MenuItem>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
              </Link>
              <Link
                to="/Users"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">User Listing</Typography>
                </MenuItem>
              </Link>
              <Link
                to="/SubscriptionPlans"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Subscription Plans</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={LogoLight} alt="VEToolkit" />
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  mx: 2,
                  color: "#B0DAF3",
                  lineHeight: "1",
                  fontSize: "14px",
                }}
              ></Typography>
            </Box>
            <Link to="/">
              <Button
                sx={{
                  my: 2,
                  px: 2,
                  m: 1,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FFFFFF26",
                  },
                }}
                className={pathname === "/" ? "NavBtnActive" : ""}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/Users">
              <Button
                sx={{
                  my: 2,
                  px: 2,
                  m: 1,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FFFFFF26",
                  },
                }}
                className={pathname === "/Users" ? "NavBtnActive" : ""}
              >
                User Listing
              </Button>
            </Link>
            <Link to="/SubscriptionPlans">
              <Button
                sx={{
                  my: 2,
                  px: 2,
                  m: 1,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FFFFFF26",
                  },
                }}
                className={
                  pathname === "/SubscriptionPlans" ? "NavBtnActive" : ""
                }
              >
                Subscription Plans
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" sx={{ boxShadow: "none" }}>
              <IconButton
                onClick={handleClick}
                disableRipple
                sx={{
                  ":hover": { backgroundColor: "transparent" },
                  padding: "0",
                }}
              >
                <Stack
                  sx={{
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mr: 2,
                      mt: "6px",
                      fontSize: "1rem",
                      color: "#212121",
                      lineHeight: "1",
                      textAlign: "end",
                      color: "#ffffff",
                    }}
                    className="capitalize "
                  >
                    {AccountData?.user?.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mr: 2,
                      mt: "1px",
                      fontSize: "0.8rem",
                      textAlign: "end",
                      color: "#ffffff",
                    }}
                    className="capitalize "
                  >
                    {AccountData?.user?.email}
                  </Typography>
                </Stack>
                <Avatar variant="rounded" src={UserAvatar} />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link
                to="/AccountSeeting"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                      backgroundColor: "#EAF2FF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "16px",
                      flexGrow: 1,
                    }}
                  >
                    Account Settings
                    <LeftArrowIcon />
                  </Typography>
                </MenuItem>
              </Link>

              <Link
                to="/ChangePassword"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                      backgroundColor: "#EAF2FF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "16px",
                      flexGrow: 1,
                    }}
                  >
                    Change Password
                    <LeftArrowIcon />
                  </Typography>
                </MenuItem>
              </Link>
              <MenuItem
                onClick={handleLogout}
                sx={{
                  "&:hover": {
                    color: "primary.main",
                    backgroundColor: "#EAF2FF",
                  },
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "16px",
                    flexGrow: 1,
                  }}
                >
                  Sign Out
                  <LeftArrowIcon />
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
