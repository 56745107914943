/**
 * @function getFromSessionStorage
 * @param {string} key - The key to lookup
 * @description
 *   Get stuff from session storage
 * @return {*} The response from session storage
 * */
export const getFromSessionStorage = (key) => {
  if (!window.sessionStorage) return null;

  return window.sessionStorage.getItem(key) || null;
};

/**
 * @function setToSessionStorage
 * @param {string} key - The key to use during storage
 * @param {*} val - The value to store
 * @description
 *   Set stuff to session storage
 * */
export const setToSessionStorage = (key, val) => {
  if (!window.sessionStorage) return;

  window.sessionStorage.setItem(key, val);
};

/**
 * @function deleteFromSessionStorage
 * @param {string} key - The key to use during storage
 * @description
 *   Delete stored stuff from session storage
 * */
export const deleteFromSessionStorage = (key) => {
  if (!window.sessionStorage) return;

  window.sessionStorage.removeItem(key);
};
