import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Stack,
  SvgIcon,
  TablePagination,
  TextField,
  Chip,
  Typography,
  Menu,
  Grid,
  InputLabel,
} from "@mui/material";
import { ReactComponent as FilterIcon } from "../../assets/images/icons/filter-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/DownloadIcon.svg";

const Invoices = () => {
  const [tableHeight, setTableHeight] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTableHeight(innerHeight - 236);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ borderRadius: "10px", background: "#FFF" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
          sx={{ padding: 2, paddingBottom: 0 }}
        >
          <Typography variant="h5" component="h5">
            Invoices
          </Typography>
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            <Button
              variant="contained"
              color="grey"
              startIcon={<FilterIcon />}
              onClick={handleFilterClick}
            >
              Filter
            </Button>
          </Stack>
        </Stack>
        <TableContainer
          component={Paper}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            overflow: "auto",
            height: { xs: "auto", md: `${tableHeight}px` },
            boxShadow: "none",
            marginTop: "10px",
          }}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: "30px", paddingX: 1 }}>
                  #
                </TableCell>
                <TableCell align="left">User Name</TableCell>
                <TableCell align="left">Invoice No</TableCell>
                <TableCell align="left">Subscription Plan</TableCell>
                <TableCell align="left">Issue Date</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ paddingX: 1 }}>
                    {++index}
                  </TableCell>
                  <TableCell align="left">{row.UserName}</TableCell>
                  <TableCell align="left">{row.InvoiceNo}</TableCell>
                  <TableCell align="left">
                    <Chip
                      size="small"
                      label={row.SubscriptionPlan}
                      color={
                        row.SubscriptionPlan === "Basic"
                          ? "successChip"
                          : row.SubscriptionPlan === "Standard"
                          ? "primaryChip"
                          : "errorChip"
                      }
                    />
                  </TableCell>
                  <TableCell align="left">{row.IssueDate}</TableCell>
                  <TableCell align="left">{row.DueDate}</TableCell>
                  <TableCell align="left">
                    <Chip
                      size="small"
                      label={row.Status}
                      color={
                        row.Status === "Paid"
                          ? "successChip"
                          : row.Status === "Pending"
                          ? "warningChip"
                          : "errorChip"
                      }
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ color: "primary.main" }}>
                    {row.Price}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{ justifyContent: "end" }}
                    >
                      <IconButton size="small" className="EyeIconButton">
                        <SvgIcon component={DownloadIcon} inheritViewBox />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ paddingX: 3, borderTop: "1px solid #D4D4D4" }}>
          <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        sx={{
          mt: 1,
          "& .MuiPaper-root": {
            width: "487px",
            padding: "2px",
            borderRadius: "8px",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.01), 0px 3px 14px 2px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box sx={{ px: 2 }}>
          <Typography
            variant="h6"
            sx={{
              borderBottom: "1px solid #D4D4D4",
              paddingBottom: "8px",
              marginBottom: "8px",
            }}
          >
            Apply Filter
          </Typography>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={6}>
              <InputLabel
                sx={{ color: "#666672", fontSize: "14px", fontWeight: 500 }}
              >
                From Date
              </InputLabel>
              <TextField
                placeholder="Please enter"
                type="date"
                variant="outlined"
                color="primary"
                fullWidth
                hiddenLabel
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{ color: "#666672", fontSize: "14px", fontWeight: 500 }}
              >
                To Date
              </InputLabel>
              <TextField
                placeholder="Please enter"
                type="date"
                variant="outlined"
                color="primary"
                fullWidth
                hiddenLabel
                size="small"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            pt: 2,
            px: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="contained" color="grey" onClick={handleFilterClose}>
            Cancel
          </Button>
          <Box sx={{ mx: 1 }} /> 
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterClose}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default Invoices;

const tableData = [
  {
    UserName: "John Doe",
    InvoiceNo: "abc1234",
    SubscriptionPlan: "Premium",
    IssueDate: "10 April 2024",
    DueDate: "20 April 2024",
    Status: "Paid",
    Price: "$150",
  },
  {
    UserName: "John Doe",
    InvoiceNo: "abc1234",
    SubscriptionPlan: "Basic",
    IssueDate: "10 April 2024",
    DueDate: "20 April 2024",
    Status: "Unpaid",
    Price: "$150",
  },
  {
    UserName: "John Doe",
    InvoiceNo: "abc1234",
    SubscriptionPlan: "Standard",
    IssueDate: "10 April 2024",
    DueDate: "20 April 2024",
    Status: "Pending",
    Price: "$150",
  },
];
