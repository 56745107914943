import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { useNavigate } from "react-router-dom";
import { useGetAllSubscriptionPlans } from "../../hooks/common";
import Loader from "../../Common/Loader";

const SubscriptionPlans = () => {
  const [tableHeight, setTableHeight] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTableHeight(innerHeight - 183);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const { data: AllSubscriptionPlans, isLoading } =
    useGetAllSubscriptionPlans();

  const handleDetailsView = (row) => {
    navigate("/SubscriptionUsers", { state: row.id });
  };

  return (
    <>
      <Box sx={{ borderRadius: "10px", background: "#FFF" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
          sx={{ padding: 2, paddingBottom: 0 }}
        >
          <Box>
            <Typography variant="h5" component="h5">
              Subscription Plans
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              overflow: "auto",
              height: { xs: "auto", md: `${tableHeight}px` },
              boxShadow: "none",
              marginTop: "10px",
              position: "relative",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: "30px", paddingX: 1 }}>
                      #
                    </TableCell>
                    <TableCell align="left">Package Name</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllSubscriptionPlans?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { backgroundColor: "#E6F3FB66" },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ paddingX: 1 }}
                      >
                        {++index}
                      </TableCell>
                      <TableCell align="left">{row?.name}</TableCell>
                      <TableCell align="left">
                        {row?.intervalCount + " " + row?.interval}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "primary.main" }}>
                        {row?.currency == "usd" || row?.currency == "USD"
                          ? "$" + row?.amount
                          : row?.amount + " " + row?.currency}
                      </TableCell>

                      <TableCell align="right">
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{ justifyContent: "end" }}
                        >
                          <IconButton
                            size="small"
                            className="EyeIconButton"
                            onClick={() => handleDetailsView(row)}
                          >
                            <SvgIcon component={EyeIcon} inheritViewBox />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionPlans;
const tableData = [
  {
    PackageName: "Basic",
    Duration: "1 Month",
    Price: "$80",
    Availability: "Active",
  },
  {
    PackageName: "Standard",
    Duration: "6 Months",
    Price: "$80",
    Availability: "Active",
  },
  {
    PackageName: "Premium",
    Duration: "1 Year",
    Price: "$80",
    Availability: "Active",
  },
];
